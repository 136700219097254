<template>
  
  <div class="mo-reserve-wrap">
    <div class="mo-reserve-wrap--title mt-15">{{ IS_REV ? '예약 변경하기' : '예약하기' }}</div>
    <div class="mo-reserve-wrap--survey-form type-reserve">
      <div class="mo-reserve-wrap--survey-form-list">
        <div class="mo-reserve-wrap--survey-form-title required">예약일을 선택해주세요.</div>
        <div class="mo-reserve-wrap--survey-form-a">
          <div class="mo-reserve-wrap--reserve">
            <div class="mo-reserve-wrap--reserve--time">{{ IS_REV ? BF_CNSL_RSVT_DT : '' }}</div>
            <!-- <button type="button" class="btn-round" @click="fnFindDatetime">{{ IS_REV ? '수정' : '선택' }}</button> -->
          </div>
        </div>
      </div>
      <div class="mo-reserve-wrap--survey-form-list">
        <div class="mo-reserve-wrap--survey-form--calendar">
          <div class="calendar-wrap" style="width:100%;margin-top:10px;">
            <div class="calendar-modal">
              <v-date-picker
                v-model=SEL_DATE
                full-width
                color="#F4B228"
                event-color="#F4B228"
                locale="ko-KR"
                :first-day-of-week="0"
                :day-format="mixin_getDate"
                :min=DATE_MIN
                :max=DATE_MAX
                @change="fnSelDate"
              >
              </v-date-picker>
            </div>
          </div>

          <div class="mo-reserve-wrap--survey-form--timepick-wrap">
          <div class="mo-reserve-wrap--survey-form--timepick" v-for="(item, index) in BTN_TIME_LIST" :key="item.CTIME" @click="fnSelTime(item)">
            <!-- <v-btn-toggle v-model="SEL_TIME" @change="fnSelTime" style="display:inline-block;"> -->
              <v-btn class="mo-reserve-wrap--survey-form--timepick-inner" style="box-shadow: none !important" :class="{ 'selected': item.selected&&item.REMAIN>0 }" :disabled="item.REMAIN==0?true:false">
                <strong>{{ item.CTIME }}</strong>
             <!--    <span>{{ item.REMAIN==0 ? '예약마감' : '잔여 ' + item.REMAIN }}</span> -->
              </v-btn>
            <!-- </v-btn-toggle> -->
          </div>
        </div>
        </div>
      </div>

      <div class="mo-reserve-wrap--survey-form-list">
        <div class="mo-reserve-wrap--survey-form-title required">방문예약</div>
        <div class="mo-reserve-wrap--survey-form-a">
          <div class="mo-reserve-wrap--reserve">
            <div class="mo-reserve-wrap--reserve--time"> 
              {{ CHOICE_DAY_TIME }}
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="mo-reserve-wrap--btn type-free" >
      <button type="button" class="sendBtnCla" @click="fnRevSave" >제출하기</button>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import api from "@/store/apiUtil";
import moment from "moment";
import { mixin } from "@/mixin/mixin";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "MENU_CAM0610", //name은 'MENU_' + 파일명 조합
  components: {

  },
  metaInfo() {
        return {
            title: "사전방문예약",
        }
    },
  mixins:[mixin],
  data() {
    return {
      CHOICE_DAY_TIME:'',
      cmpList: [],
      custItem: {},
      custCnt: 0,
      startTime: '', //근무시작시간
      endTime: '', //근무종료시간
      spTime: 60, //상담분리타임(분)
      dateArr: [],
      dayByTimeManArr: [],
      saveYn:true,

      USER_ID:'',
      PAGE_TITLE : '',
      CMP_ID : '',    //캠페인ID
      CMP_NAME : '',    //캠페인nm
      CUST_ID : '',   //고객ID
      SEL_DATE : '',  //예약일자
      WEEKDAY:'',
      DATE_MIN : '',  //최소선택가능일자
      DATE_MAX : '',  //최대선택가능일자
      BTN_TIME_LIST : [],
      SEL_TIME : '',
      REV_DT : '',
      REV_VIEW_DT : '',
      SEL_SHOW : false,
      IS_REV : false,
      SAME_DATE_CHK : '',

      CMP_CNSL_ID:'',
      QUTN_ID:0,
      BUSS_CODE:'',
      CUST_PHONE:'',
      CUST_NAME:'',
      MKTG_AGRE_YN:'Y',
      PRSN_AGRE_YN:'Y',
      PLUS_FRED_KEY:'',
      VIST_GUB_CD:'CNSL_VISIT_CD_01',
      QUTN_ITEM_LIST:'',
      CHK_QUTN_RPLY:'',
      SEL_QUTN_RPLY:'',
      TXT_QUTN_RPLY:'',
      ETC_QUTN_RPLY:'',
      BF_CNSL_RSVT_DT:'',

      alertMsg:{
        error:'관리자에게 문의하세요.',
        confirmSave:'저장 하시겠습니까.',
        beforeSaveChk:'현장방문 미등록 고객입니다.\n등록 후 상담예약을 진행해주세요.',
        advanReservSave:'사전예약 날짜를 변경하시겠습니까?',
        doNotRes:'해당 시간은 이미 예약이 마감됐습니다.',
        endCampMsg:'종료된 캠페인입니다.',
        visitCormMsg:"고객님 방문예약 하시겠습니까?",
        visitModiCormMsg:"고객님 방문예약 변경 하시겠습니까?",
      },
    }
  },

  mounted() {
    this.init();
    $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">');
  },

  methods: {
    
    fnConfirmChk() {
      let msg=''
      if(this.IS_REV){
        msg=this.alertMsg.visitModiCormMsg
      }else{
        msg=this.alertMsg.visitCormMsg
      }
      var result = confirm(msg);
      if(result){
        this.fnOneLoad()
      }else{
        var _ua = window.navigator.userAgent || window.navigator.vgjendor || window.opera;
        window.location.href = (/iPad|iPhone|iPod/.test(_ua)) ? "kakaoweb://closeBrowser" : "kakaotalk://inappbrowser/close"

        // var Url = location.href;
        // var Agent = navigator.userAgent.toLowerCase();
        // console.log("[window ready] : [접속 Url] : " + Url);
        // console.log("[window ready] : [접속 Agent] : " + Agent);
        // if(Agent.includes("kakao")){ // 카카오 브라우저로 실행 시킨 경우
        //   // 먼저, 카카오 인앱 브라우저 닫기
        //   location.href = 'kakaotalk://inappbrowser/close';
        //   if(!navigator.userAgent.match(/iPhone|iPad/i)){ // 안드로이드 접속 경우
        //     console.log("");
        //     console.log("[window ready] : [접속 모바일] : " + "[안드로이드]");
        //     console.log("");
        //     // 크롬으로 새창 열기
        //     location.href='intent://'+location.href.replace(/https?:\/\//i,'')+'#Intent;scheme=http;package=com.android.chrome;end';
        //   }
        // }
      }
    },

    fnOneLoad() {
      window.onload = function() {
        if(!window.location.hash) {
            window.location = window.location + '#loaded';
            window.location.reload();
        }
      }
    },
    async init(){
      //파라미터 수신
      if(!this.mixin_isEmpty(this.$route.query.cmp_id)){
        this.CMP_ID = this.$route.query.cmp_id.replace(/[\"\']/gi,'')   //캠페인ID
      }
      if(!this.mixin_isEmpty(this.$route.query.buss_code)){
        this.BUSS_CODE = this.$route.query.buss_code.replace(/[\"\']/gi,'')   //사업지 코드
      }
      if(!this.mixin_isEmpty(this.$route.query.mktg_agre_yn)){
        this.MKTG_AGRE_YN = this.$route.query.mktg_agre_yn.replace(/[\"\']/gi,'') //마케팅 동의 여부
      }
      if(!this.mixin_isEmpty(this.$route.query.prsn_agre_yn)){
        this.PRSN_AGRE_YN = this.$route.query.prsn_agre_yn.replace(/[\"\']/gi,'') //개인정보 수집 동의 여부
      }
      if(!this.mixin_isEmpty(this.$route.query.cust_phone)){
        this.CUST_PHONE = this.$route.query.cust_phone.replace(/[\"\']/gi,'') //고객 핸드폰번호
      }
      if(!this.mixin_isEmpty(this.$route.query.plus_fred_key)){
        this.PLUS_FRED_KEY = this.$route.query.plus_fred_key.replace(/[\"\']/gi,'')  //플러스 친구 사용키
      }
      
      console.log('CMP_ID:',this.CMP_ID)
      console.log('MKTG_AGRE_YN:',this.MKTG_AGRE_YN)
      console.log('PRSN_AGRE_YN:',this.PRSN_AGRE_YN)
      console.log('CUST_PHONE:',this.CUST_PHONE)
      console.log('PLUS_FRED_KEY:',this.PLUS_FRED_KEY)

      // this.CUST_ID = this.$route.query.cust_id  //고객ID
      // this.CUST_NAME = this.$route.query.cust_name  //고객 이름

      //캠페인정보 조회 후 캠페인진행 시작, 종료 일자, 근무 시작, 종료 시간 상담분리시간, 상담제외시작 셋팅
      //고객체크후 기예약자인경우 아래 변수 셋팅하여 예약 변경 하도록 처리
      //this.IS_REV = true;
      //this.REV_DT = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);

      this.fnConnCmp()
      this.USER_ID = this.$store.getters['userStore/GE_USER_ROLE'].userId;

    },
    dayConvert(e) {
      const day = e.split("-");
      return `${day[0]}.${day[1]}.${day[2]}`;
    },
    getWeekDay (dateDay) {
      const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
      let i = new Date(dateDay).getDay(dateDay)
      return daysOfWeek[i]
    },
    async fnConnCmp () {
      let requestData = {
        headers: {
          SERVICE:this.initHeaders.SERVICE,
          METHOD:'data',
          TYPE:this.initHeaders.TYPE,
          URL:"/api/hlw/advanReserv/campaign/manage/data",
        },
        sendData: {}
      }

      requestData.sendData["CMP_ID"]=this.CMP_ID
      requestData.sendData["BUSS_CODE"]=this.BUSS_CODE
      requestData.sendData["CUST_ID"]=this.CUST_ID
      requestData.sendData["CUST_PHONE"]=this.CUST_PHONE
      requestData.sendData["CUST_USER_KEY"]=this.PLUS_FRED_KEY

      //api 호출 부분
      let responseGridList = await this.common_postCall(requestData)
      let resHeader = responseGridList.HEADER
      let dataCmpList = responseGridList.DATA_CMP_LIST
      let dataCust = responseGridList.DATA_CUST
      let dataCustInfo = responseGridList.DATA_CUST_INFO

      // 데이터 조회 오류 시 alert
      if (resHeader.ERROR_FLAG) {
        this.common_alert(this.alertMsg.error, "error")
        return false;
      }

      this.getInitCallBack(dataCmpList, dataCust, dataCustInfo)
    },
    fnFindDatetime(){
      this.SEL_SHOW = true
    },
    fnSelTime(item){
      if(item.REMAIN<=0){
        this.saveYn=false

      }else{
        this.saveYn=true
        document.getElementsByClassName('sendBtnCla')[0].style.backgroundColor='#F1B02A'

        for (let i = 0; i < this.BTN_TIME_LIST.length; i++) {
          this.BTN_TIME_LIST[i].selected = false;
        }
        
        this.BTN_TIME_LIST[this.BTN_TIME_LIST.indexOf(item)].selected = true;
        this.SEL_TIME=item.CTIME
        this.CHOICE_DAY_TIME=this.SEL_DATE+' ('+this.WEEKDAY+') '+this.SEL_TIME
        //this.SEL_SHOW = false
        //this.BTN_TIME_LIST = []
        var revDt=moment(this.SEL_DATE + ' ' + this.SEL_TIME, 'YYYY-MM-DD HH:mm')
        this.REV_VIEW_DT=revDt.format('YYYY년MM월DD일 HH시mm분')
        this.REV_DT=revDt.format('YYYY-MM-DD HH:mm:ss')
      }
    },
    fnSelDate(){
      if(this.SEL_DATE != undefined && this.SEL_DATE != '') {

        for (let i = 0; i < this.BTN_TIME_LIST.length; i++) {
          this.BTN_TIME_LIST[i].selected = false;
        }

        this.WEEKDAY=this.getWeekDay(this.SEL_DATE)
        this.CHOICE_DAY_TIME=this.SEL_DATE+' ('+this.WEEKDAY+') '
        const timeRangeList=this.dayByTimeManArr[this.SEL_DATE].timeRangeList.split(',')
        this.startTime=timeRangeList[0]
        this.endTime=timeRangeList[timeRangeList.length-1]
        let maxReservTimeCnt=this.dayByTimeManArr[this.SEL_DATE].maxReservTimeCnt //시간별 최대 예약 건수

        var dtStartTime=moment(this.startTime, 'HH:mm')
        var dtEndTime=moment(this.endTime, 'HH:mm')

        this.fnConnCust(this.startTime, this.endTime, dtStartTime, dtEndTime, maxReservTimeCnt, timeRangeList)
        
        var revDt=moment(this.SEL_DATE + ' ', 'YYYY-MM-DD HH:mm')
        this.REV_VIEW_DT=revDt.format('YYYY년MM월DD일 HH시mm분')
        this.REV_DT=revDt.format('YYYY-MM-DD HH:mm:ss')
        document.getElementsByClassName('sendBtnCla')[0].style.backgroundColor='#EDEDED'
        console.log('SEL_DATE:', this.SEL_DATE)
      }
    },
    async fnConnCust (stTime, edTime, dtStartTime, dtEndTime, maxReservTimeCnt, timeRangeList) {
      let requestData = {
        headers: {
          SERVICE:this.initHeaders.SERVICE,
          METHOD:'data',
          TYPE:this.initHeaders.TYPE,
          URL:"/api/hlw/advanReserv/campaign/manage/cust/data",
        },
        sendData: {}
      }

      requestData.sendData["CMP_ID"]=this.CMP_ID
      requestData.sendData["RESERV_DAY"]=this.SEL_DATE
      requestData.sendData["ST_TIME"]=stTime
      requestData.sendData["ED_TIME"]=edTime

      //api 호출 부분
      let responseGridList = await this.common_postCall(requestData)
      let resHeader = responseGridList.HEADER
      let resData = responseGridList.DATA

      // 데이터 조회 오류 시 alert
      if (resHeader.ERROR_FLAG) {
        this.common_alert(this.alertMsg.error, "error")
        return false;
      }

      const retDatas=resData
      console.log(retDatas)
      this.BTN_TIME_LIST=[]
      let cnt=0;
      for (let index = 0; index < timeRangeList.length; index++) {
        const element = timeRangeList[index];
        
        let retData=''
        let timeByCnt=0

        let oTime = {}
        oTime.CTIME=moment(element, 'HH:mm').format('HH:mm')
        oTime.VTIME=moment(element, 'HH시mm분').format('HH시mm분')

        const todayDate=new Date()
        
        if(retDatas.length>0){
          retData=retDatas[cnt]
          if(!this.mixin_isEmpty(retData)){
            if(retData.CNSL_RSVT_TIME===oTime.CTIME){
              timeByCnt=retData.TIME_BY_CNT
              cnt++
            }
          }
        }

        oTime.REMAIN=maxReservTimeCnt-timeByCnt
        let choiceDate=new Date(this.SEL_DATE+' '+oTime.CTIME)
        if(oTime.REMAIN<=0||todayDate>choiceDate){
          oTime.REMAIN=0
        }
        this.BTN_TIME_LIST.push(oTime)
      }

    },
    fnRevSave () {
      if(this.mixin_isEmpty(this.REV_DT) || this.mixin_isEmpty(this.SEL_TIME)){
        this.common_alert("예약하려는 일자, 시간을 선택해 주세요.", "error")
        return
      }

      //현재 선택한 날짜
      //이전에 사전 예약 후 상담받은 날짜
      if(this.SEL_DATE===this.SAME_DATE_CHK){
        this.common_alert("해당 일자는 이미 상담을 받으신 일자입니다.", "error")
        return
      }

      if(!this.saveYn){
        this.common_alert(this.alertMsg.doNotRes)
        return
      }

      if(this.IS_REV){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: this.alertMsg.advanReservSave,
          iconClass: 'svg-error-lg',
          type: 'confirm',
          callYes: this.fnConnCmpCustRegist,
          callNo: this.callNo,
        })
      }else{
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: this.alertMsg.confirmSave,
          iconClass: 'svg-error-lg',
          type: 'confirm',
          callYes: this.fnConnCmpCustRegist,
          callNo: this.callNo,
        })
      }
      //예약내용 저장처리후 고객에게 알림발송
    },

    callNo () {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    
    async fnConnCmpCustRegist () {
      this.callNo()

      let requestData = {
        headers: {
          SERVICE:this.initHeaders.SERVICE,
          METHOD:'data',
          TYPE:this.initHeaders.TYPE,
          URL:"/api/hlw/advanReserv/campaign/manage/advanceReser/cmpCust/regist",
        },
        sendData: {}
      }

      requestData.sendData["CMP_CNSL_ID"]=this.CMP_CNSL_ID
      requestData.sendData["CMP_ID"]=this.CMP_ID
      requestData.sendData["BUSS_CODE"]=this.BUSS_CODE
      requestData.sendData["CUST_ID"]=this.CUST_ID
      requestData.sendData["CUST_PHONE"]=this.CUST_PHONE
      requestData.sendData["CUST_NAME"]=this.CUST_NAME
      requestData.sendData["MKTG_AGRE_YN"]='Y'
      requestData.sendData["PRSN_AGRE_YN"]='Y'
      requestData.sendData["CNSL_RSVT_DT"]=this.REV_DT

      if(this.mixin_isEmpty(this.QUTN_ID)){
        this.QUTN_ID=0
      }
      requestData.sendData["QUTN_ID"]=this.QUTN_ID
      requestData.sendData["QUTN_ITEM_LIST"]=this.QUTN_ITEM_LIST
      requestData.sendData["CHK_QUTN_RPLY"]=this.CHK_QUTN_RPLY
      requestData.sendData["SEL_QUTN_RPLY"]=this.SEL_QUTN_RPLY
      requestData.sendData["TXT_QUTN_RPLY"]=this.TXT_QUTN_RPLY
      requestData.sendData["ETC_QUTN_RPLY"]=this.ETC_QUTN_RPLY
      requestData.sendData["PLUS_FRED_KEY"]=this.PLUS_FRED_KEY
      requestData.sendData["VIST_GUB_CD"]=this.VIST_GUB_CD //미방문
      requestData.sendData["USE_YN"]='Y'
      requestData.sendData["REG_ID"]=this.USER_ID

      //api 호출 부분
      let responseGridList = await this.common_postCall(requestData);
      let resHeader = responseGridList.HEADER;
      let resBotData = responseGridList.DATA;
      let resCnslId = responseGridList.DATA_CNSL_ID;
      
      // 데이터 조회 오류 시 alert
      if (resHeader.ERROR_FLAG) {
        this.common_alert(this.alertMsg.error, "error")
        return false;
      }
      
      //const retDatas=resBotData
      console.log('제출하기 후 resBotData:', resBotData);
      console.log('제출하기 후 resCnslId:', resCnslId);
      //예약변경의 경우 체크하여 알림메세지 분기
      if(this.IS_REV){
        this.sendEventApi(resBotData,resCnslId,"MODIFY");
        this.common_alert("예약 변경 처리가 완료 되었습니다.")
      }else{
        this.sendEventApi(resBotData,resCnslId,"CHECK");
        this.common_alert("예약 처리가 완료 되었습니다.")
      }
      setTimeout(() => this.closePage(), 1500);
    },
    sendEventApi(resBotData,resCnslId,resInfo){
  
      let eventName = "eventnew";
      let plusfriendUserKey = this.PLUS_FRED_KEY;
      let apiKey =  resBotData[0].BLOCK_API_KEY;
      let botId = resBotData[0].BOT_ID;
      let cmpCnslId =  resCnslId[0].CMP_CNSL_ID;
      this.CMP_CNSL_ID = cmpCnslId;
      let custPhone = this.CUST_PHONE;
      let bussCode = this.BUSS_CODE;
    
      let skillApiUrl = process.env.VUE_APP_KKO_SKILL_BACKEND_URL+'/skill/adminEventApi';
    
      $.ajax({
        url: skillApiUrl,
        dataType: "json",
        type: "POST",
        ContentType: "text/plain",
        crossDomain: true,

      data: {
          eventName: eventName,
          plusfriendUserKey: plusfriendUserKey,
          apiKey: apiKey,
          botId: botId,
          cmpCnslId: cmpCnslId,
          custPhone: custPhone,
          bussCode: bussCode,
          resInfo:  resInfo
      },
        success: function (data) {
          console.log('adminEventApi SUCC : ', data)
        },
        error: function (request, status, error) {
          console.log('adminEventApi ERR : ', error)
        },
      })

    },
    closePage (){
      var _ua = window.navigator.userAgent || window.navigator.vgjendor || window.opera;
      //alert(_ua.toLocaleLowerCase().indexOf("kakaotalk"))
      if (_ua.toLocaleLowerCase().indexOf("kakaotalk") > -1) {
        window.location.href = (/iPad|iPhone|iPod/.test(_ua)) ? "kakaoweb://closeBrowser" : "kakaotalk://inappbrowser/close"
      }else{
        window.open("about:blank","_self").close()
      }

    },

    getInitCallBack (dataCmpList, dataCust, dataCustInfo) {
      this.cmpList=dataCmpList

      //해당 캠페인 사전등록한 고객 데이터
      if(dataCust!=null&&dataCust.length>0){
        dataCust=dataCust[0] //최근 예약한 날짜
        console.log('사전예약 고객 : ', dataCust)
        if(dataCust.VIST_GUB_CD==='CNSL_VISIT_CD_01'){ //최근 예약한 날짜가 미방문일 경우 예약 수정
          this.custCnt=dataCust.TOT_CNT
          this.custItem=dataCust
          this.IS_REV=true //이미 사전등록했던 고객
          this.CMP_CNSL_ID=dataCust.CMP_CNSL_ID
  
          if(!this.mixin_isEmpty(dataCust.CNSL_RSVT_DT)){
            const cnslDayTime=dataCust.CNSL_RSVT_DT.split(' ')
            const dateDay=cnslDayTime[0]
            const dateTime=cnslDayTime[1]
            this.BF_CNSL_RSVT_DT=dateDay+' ('+this.getWeekDay(dateDay)+') '+dateTime
          }
        }else{ //최근 예약한 날짜가 현장 방문완료일 경우 체크
          //현장 방문 후 현장 방문 날짜와 같은 날짜를 또 선택할 경우 예약 불가능 처리
          const cnslDayTime=dataCust.CNSL_RSVT_DT.split(' ')
          const dateDay=cnslDayTime[0]
          this.SAME_DATE_CHK=dateDay
        }
      }

      //처음예약 또는 예약변경 체크후 카카오 인앱브라우저 에러처리
      if(!window.location.hash) {
        this.fnConfirmChk()
      }

      if(dataCustInfo!=null&&dataCustInfo.length>0){
        if(!this.mixin_isEmpty(dataCustInfo[0].CUST_ID)){
          dataCustInfo=dataCustInfo[0]
          this.CUST_ID=dataCustInfo.CUST_ID
          this.CUST_NAME=dataCustInfo.CUST_NAME
          this.BUSS_CODE=dataCustInfo.BUSS_CODE
        }else{
          this.common_alert(dataCustInfo[1].MSG)
          setTimeout(() => this.closePage(), 1500);
        }
      }
      
      if(this.cmpList!==null&&this.cmpList.length>0){
        const todayDate=new Date()

        let yyyy=todayDate.getFullYear()
        let mm=todayDate.getMonth()+1>9?(todayDate.getMonth()+1):'0'+(todayDate.getMonth()+1)
        let dd=todayDate.getDate()>9?todayDate.getDate():'0'+todayDate.getDate()

        for (let index = 0; index < this.cmpList.length; index++) {
          const cmp = this.cmpList[index]
          this.dateArr.push(cmp.RESERV_DAY)

          if(index===0){
            this.DATE_MIN=this.dateArr[index]

            const cmpDate=new Date(this.DATE_MIN)
            if(todayDate>cmpDate){
              let lastTime=''
              for (let i = 0; i < this.cmpList.length; i++) {
                const element = this.cmpList[i]
                if(element.RESERV_DAY===yyyy+'-'+mm+'-'+dd){
                  const timeRList=cmp.TIME_RANGE_LIST.split(',')
                  lastTime=timeRList[timeRList.length-1]+':00'
                  // let minDate=new Date(element.RESERV_DAY+' '+timeRList[timeRList.length-1]+':59')
                }
              }
  
              const cmpLastTime=new Date(yyyy+'-'+mm+'-'+dd+' '+lastTime)
              if(todayDate>cmpLastTime){
                dd=todayDate.getDate()>9?todayDate.getDate()+1:'0'+(todayDate.getDate()+1)
              }
              this.DATE_MIN=yyyy+'-'+mm+'-'+dd
            }
            this.QUTN_ID=cmp.QUTN_ID
          }
          
          if(index===this.cmpList.length-1){
            this.DATE_MAX=this.dateArr[index]
          }

          let obj={
            reservDay:this.dateArr[index],
            timeRangeList:cmp.TIME_RANGE_LIST,
            cnslTimeUnit:cmp.CNSL_TIME_UNIT,
            totalReservPers:cmp.TOTAL_RESERV_PERS,
            maxReservTimeCnt:cmp.MAX_RESERV_TIME_CNT,
            qutnId:cmp.QUTN_ID,
          }
          this.dayByTimeManArr[this.dateArr[index]]=obj
        }

        const maxDate=new Date(this.DATE_MAX)
        //캠페인 시작날짜가 현재 날짜보다 전일 경우 창 종료
        if(todayDate>maxDate){
          this.common_alert(this.alertMsg.endCampMsg)
          setTimeout(() => this.closePage(), 1500);
        }
        
      }else{
        //창 종료
        this.common_alert(this.alertMsg.endCampMsg)
        setTimeout(() => this.closePage(), 1500);
      }
    },
  },

  computed: {
    ...mapState({
      stateToken: "authStore/token",
    }),
    initHeaders(){
      return {
        SERVICE : 'hlw.campaign.manage',
        METHOD : "",
        TYPE : 'BIZ_SERVICE',
      };
    },
    paramData(){
      return {
      };
    }
  },
};
</script>